import React from 'react';

export const getSizeDimension = (sizes, attributeGroups) => {
  return sizes?.map((size, index) => {
    return (size
      && (
        <tr key={index}>
          <td>{sizes[index]}</td>
          {(attributeGroups?.map((group) => {
            const { attributes } = group;
            const dimensions = attributes?.find((attribute) => attribute?.attributeName === size)?.dimensions;
            return dimensions || '\u2014';
          }))?.map((dimension, i) => {
            return (<td key={i}>{dimension}</td>);
          })}
        </tr>
      )
    );
  });
};

export const getAllSizes = (attributeGroups = []) => {
  const attributes = attributeGroups.map((group) => group?.attributes);
  const sizes = attributes?.map((attribute) => {
    return attribute?.map((attr) => {
      const { attributeName = '' } = attr;
      return attributeName;
    });
  });
  const allSizes = sizes?.length && [...new Set(sizes.reduce((prev, curr) => prev.concat(curr)))];
  return allSizes;
};

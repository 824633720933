import React, { useState, useEffect, useContext } from 'react';
import { DropDown, DropDownItem } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { shape, string } from 'prop-types';
import { publish } from '../../publisher';
import { SizeGuideTable } from '../size-guide-table/size-guide-table';
import './size-guide-dropdown.scss';

export const SizeGuideDropdown = (props) => {
  const {
    sizeGuideResponse = {},
    productType
  } = props;

  const { channel = 'desktop' } = useContext(ExperienceContext) || {};
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [table, setTable] = useState(null);
  const [dropdownTitle, setDropdownTitle] = useState('---select---');

  const getAttributeTable = (groupIndex, type) => {
    setDropdownTitle(type);
    return (attributeGroups?.length
      ? (
        <table className="size-guide-table">
          <thead>
            <tr>
              <th>Size</th>
              <th>{attributeGroups[groupIndex]?.dimensionLabel}</th>
            </tr>
          </thead>
          <tbody>
            {attributeGroups[groupIndex]?.attributes?.map((attribute, attributeIndex) => {
              const { attributeName, dimensions } = attribute;
              return (
                <tr key={attributeIndex}>
                  <td>{attributeName}</td>
                  <td>{dimensions}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null
    );
  };

  const setSizeGuideTable = () => {
    if (attributeGroups?.length) {
      const isCurrentAttribute = (attribute) => attribute?.productType === productType;
      const groupIndex = attributeGroups.findIndex(isCurrentAttribute);
      const sizeGuideTable = getAttributeTable(groupIndex, productType);
      setTable(sizeGuideTable);
    }
  };

  const handleDropdownOptionClick = (groupIndex, type) => {
    setTable(getAttributeTable(groupIndex, type));
    publish('size-guide.click', { productType: type });
  };

  useEffect(() => {
    if (sizeGuideResponse?.attributeGroups) {
      setAttributeGroups(sizeGuideResponse.attributeGroups);
    }
  }, [sizeGuideResponse?.attributeGroups]);

  useEffect(() => {
    if (attributeGroups.length) {
      setSizeGuideTable();
    }
  }, [attributeGroups]);

  const hasProductType = attributeGroups.length && attributeGroups.find((group) => group?.productType);
  return (
    <>
      {hasProductType ? (
        <>
          <p className="size-guide__dropdown-title">
            Sizing for:
          </p>
          <div className="size-guide__dropdown-wrapper" data-testid="dropdown">
            <DropDown header={dropdownTitle} hideOnSelection>
              {attributeGroups.map((attributeGroup, groupIndex) => {
                const { productType: type } = attributeGroup;
                return (
                  <DropDownItem
                    key={groupIndex}
                    onClick={() => handleDropdownOptionClick(groupIndex, type)}
                  >
                    {type}
                  </DropDownItem>
                );
              })}
            </DropDown>
          </div>
          {table}
        </>
      ) : (
        <SizeGuideTable sizeGuideResponse={sizeGuideResponse} />
      )}
    </>
  );
};

SizeGuideDropdown.displayName = 'SizeGuideDropdown';

SizeGuideDropdown.propTypes = {
  sizeGuideResponse: shape({}),
  productType: string
};

SizeGuideDropdown.defaultProps = {
  sizeGuideResponse: null,
  productType: null
};

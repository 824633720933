import React, { useState, useContext } from 'react';
import {
  Dropdown,
  MenuItem,
  FormController,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { shape, string } from 'prop-types';
import { publish } from '../../publisher';
import { SizeGuideTable } from '../size-guide-table/size-guide-table';

export const SizeGuideDropdown = (props) => {
  const {
    sizeGuideResponse = {},
    productType
  } = props;

  const { channel = 'desktop' } = useContext(ExperienceContext) || {};
  const [attributeGroupIndex, setAttributeGroupIndex] = useState(0);

  const handleDropdownChange = (event) => {
    setAttributeGroupIndex(event.target.value);
    publish('size-guide.click', { productType });
  };

  if (Object.keys(sizeGuideResponse).length === 0 && !sizeGuideResponse.attributeGroups) {
    return null;
  }

  const attributeGroups = sizeGuideResponse.attributeGroups;
  const currentProductType = attributeGroups[attributeGroupIndex].productType;
  const hasProductType = attributeGroups.length && currentProductType;

  return (
    <>
      {hasProductType ? (
        <>
          <div className="sui-flex sui-my-4">
            <FormController fullWidth>
              <FormLabel htmlFor="size-guide-dropdown" id="size-guide-dropdown-label">
                Sizing for:
              </FormLabel>
              <Dropdown
                id="size-guide-dropdown"
                value={attributeGroupIndex}
                onChange={handleDropdownChange}
              >
                {attributeGroups.map((attributeGroup, groupIndex) => {
                  return (
                    <MenuItem
                      value={groupIndex}
                      key={groupIndex}
                    >
                      {attributeGroup.productType}
                    </MenuItem>
                  );
                })}
              </Dropdown>
            </FormController>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Size</TableCell>
                <TableCell>{attributeGroups[attributeGroupIndex].dimensionLabel}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributeGroups[attributeGroupIndex].attributes?.map((attribute, attributeIndex) => {
                const { attributeName, dimensions } = attribute;
                return (
                  <TableRow key={attributeIndex}>
                    <TableCell>{attributeName}</TableCell>
                    <TableCell>{dimensions}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      ) : (
        <SizeGuideTable sizeGuideResponse={sizeGuideResponse} />
      )}
    </>
  );
};

SizeGuideDropdown.displayName = 'SizeGuideDropdown';

SizeGuideDropdown.propTypes = {
  sizeGuideResponse: shape({}),
  productType: string
};

SizeGuideDropdown.defaultProps = {
  sizeGuideResponse: null,
  productType: null
};

import React, { useContext } from 'react';
import {
  string, shape, arrayOf, bool, func
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Modal, ModalHeader, ModalBody } from '@one-thd/sui-atomic-components';
import { SizeGuideHeader } from '../size-guide-header/size-guide-header';
import { SizeGuideDropdown } from '../size-guide-dropdown/size-guide-dropdown';
import { SizeGuideTable } from '../size-guide-table/size-guide-table';

const SizeGuideOverlayContent = (props) => {
  const {
    sizeGuideResponse = {},
    productType,
    channelFromMock,
    open,
    onClose
  } = props;

  const {
    channel = 'desktop'
  } = useContext(ExperienceContext) || {};

  const isMobile = channelFromMock === 'mobile' || channel === 'mobile';
  const { collectionName } = sizeGuideResponse;

  return (
    <Modal
      size="lg"
      open={open}
      onClose={onClose}
    >
      <ModalHeader onClose={onClose}>
        Size Guide
      </ModalHeader>
      <ModalBody>
        {collectionName
          && (
            <div className={isMobile ? '' : 'sui-pb-5'}>
              <SizeGuideHeader collectionName={collectionName} />
            </div>
          )}
        {isMobile ? (
          <SizeGuideDropdown productType={productType} sizeGuideResponse={sizeGuideResponse} />
        ) : (
          <SizeGuideTable sizeGuideResponse={sizeGuideResponse} />
        )}
      </ModalBody>

    </Modal>
  );
};

SizeGuideOverlayContent.displayName = 'SizeGuideOverlayContent';

SizeGuideOverlayContent.propTypes = {
  channelFromMock: string,
  sizeGuideResponse: shape({
    attributeGroups: arrayOf(shape({}))
  }).isRequired,
  productType: string,
  open: bool,
  onClose: func.isRequired,
};

SizeGuideOverlayContent.defaultProps = {
  channelFromMock: null,
  productType: null,
  open: false
};

export default SizeGuideOverlayContent;

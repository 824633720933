import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const SizeGuideHeader = ({ collectionName }) => {
  return (
    <Typography variant="h5">
      {collectionName}
    </Typography>
  );
};

SizeGuideHeader.displayName = 'SizeGuideHeader';

SizeGuideHeader.propTypes = {
  collectionName: string.isRequired,
};

import React, { useContext } from 'react';
import {
  string, shape, arrayOf, bool, func
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { SizeGuideHeader } from '../size-guide-header/size-guide-header';
import { SizeGuideDropdown } from '../size-guide-dropdown/size-guide-dropdown';
import { SizeGuideTable } from '../size-guide-table/size-guide-table';

const SizeGuideOverlayContent = (props) => {
  const {
    sizeGuideResponse = {},
    productType,
    channelFromMock,
    open,
    onClose
  } = props;

  const {
    channel = 'desktop'
  } = useContext(ExperienceContext) || {};

  const isMobile = channelFromMock === 'mobile' || channel === 'mobile';
  const { collectionName } = sizeGuideResponse;

  return (
    <Overlay
      closeButton
      medium
      open={open}
      onClose={onClose}
      header="Size Guide"
    >
      <div className="size-guide" data-component="SizeGuideOverlayContent">
        {collectionName && <SizeGuideHeader collectionName={collectionName} isMobile={isMobile} />}
        {isMobile ? (
          <SizeGuideDropdown productType={productType} sizeGuideResponse={sizeGuideResponse} />
        ) : (
          <SizeGuideTable sizeGuideResponse={sizeGuideResponse} />
        )}
      </div>
    </Overlay>
  );
};

SizeGuideOverlayContent.displayName = 'SizeGuideOverlayContent';

SizeGuideOverlayContent.propTypes = {
  channelFromMock: string,
  sizeGuideResponse: shape({
    attributeGroups: arrayOf(shape({}))
  }).isRequired,
  productType: string,
  open: bool,
  onClose: func.isRequired,
};

SizeGuideOverlayContent.defaultProps = {
  channelFromMock: null,
  productType: null,
  open: false
};

export default SizeGuideOverlayContent;
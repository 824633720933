import React from 'react';
import classnames from 'classnames';
import { string, bool } from 'prop-types';
import './size-guide-header.scss';

export const SizeGuideHeader = ({ collectionName, isMobile }) => {
  const headerClasses = classnames({
    'size-guide__header': isMobile,
    'size-guide__title': !isMobile
  });

  return (
    <h2 className={headerClasses}>
      {collectionName}
    </h2>
  );
};

SizeGuideHeader.displayName = 'SizeGuideHeader';

SizeGuideHeader.propTypes = {
  collectionName: string.isRequired,
  isMobile: bool.isRequired
};

import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { getSizeDimension, getAllSizes } from '../../size-guide-utils';
import './size-guide-table.scss';

export const SizeGuideTable = (props) => {
  const {
    sizeGuideResponse: {
      attributeGroups = []
    } = {}
  } = props;

  if (!attributeGroups?.length) {
    return null;
  }
  const sizes = attributeGroups?.length && getAllSizes(attributeGroups);
  return (
    <table className="size-guide-table">
      <thead>
        <tr>
          <th>Size</th>
          {attributeGroups.map((group, index) => {
            const { productType, dimensionLabel } = group;
            return (
              <th key={index} className="size-guide-table__label">
                {productType && <span>{productType}</span>}
                <span className="size-guide-table__dimension">
                  {dimensionLabel}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {getSizeDimension(sizes, attributeGroups)}
      </tbody>
    </table>
  );
};

SizeGuideTable.displayName = 'SizeGuideTable';

SizeGuideTable.propTypes = {
  sizeGuideResponse: shape({
    attributeGroups: arrayOf(shape({}))
  }).isRequired
};

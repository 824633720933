import React from 'react';
import { arrayOf, shape } from 'prop-types';
import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@one-thd/sui-atomic-components';
import { getSizeDimension, getAllSizes } from '../../size-guide-utils';

export const SizeGuideTable = (props) => {
  const {
    sizeGuideResponse: {
      attributeGroups = []
    } = {}
  } = props;

  if (!attributeGroups?.length) {
    return null;
  }
  const sizes = attributeGroups?.length && getAllSizes(attributeGroups);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Size</TableCell>
          {attributeGroups.map((group, index) => {
            const { productType, dimensionLabel } = group;
            return (
              <TableCell key={index}>
                {productType && <><span>{productType}</span><br /></>}
                <span className="sui-font-normal">
                  {dimensionLabel}
                </span>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {getSizeDimension(sizes, attributeGroups)}
      </TableBody>
    </Table>
  );
};

SizeGuideTable.displayName = 'SizeGuideTable';

SizeGuideTable.propTypes = {
  sizeGuideResponse: shape({
    attributeGroups: arrayOf(shape({}))
  }).isRequired
};
